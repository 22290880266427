/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutAddressBook {
    &-Wrapper {
        margin-left: 36px;
        @include desktop {
            width: 530px;
        }
        @include mobile {
            margin-left: 15px;
        }
    }
    .FieldForm-Fields {
        @include wide-desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 32px;

            > *:last-child{
                grid-column: span 2;
            }
            .Field:nth-child(-n + 2) {
                margin-top: 0;
            }
        }
    }
    .Checkout-Heading {
        margin-bottom: 30px;
        font-size: 24px;
        @include desktop {
            font-size: 30px;
            margin-bottom: 18px;
        }
    }
    &-Title,
    &-Title-Selected {
        font-size: 18px;
        font-weight: 700;
        padding-left: 28px;
        margin-bottom: 10px;
        cursor: pointer;
        text-align: left;
        color: black;
        &:disabled {
            color: initial;
        }
        @include desktop {
            font-size: 21px;
        }
    }
    &-Title {
        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0%, -50%);
        }
        &:hover {
            height: auto;
            &::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(60%, -50%);
            }
        }
    }
    &-Title-Selected {
        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0%, -50%);
        }
        &::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: black;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(60%, -50%);
        }
    }
    &-Button {
        border: 0;
        color: black;
        text-transform: none;
        height: auto;
        font-size: 18px;
        font-weight: 700;
        padding-left: 28px;
        margin-bottom: 10px;
        display: block;
        @include mobile {
            text-align: left;
        }
        @include desktop {
            font-size: 21px;
        }
        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0%, -50%);
        }
        &:hover {
            height: auto;
            &::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(60%, -50%);
            }
        }
    }
    .Button_isHollow {
        &:hover {
            height: auto;
            padding-left: 28px;
        }
    }
    .CheckoutAddressBook-Button {
            &_parentIsCustomAddressExpanded {
                display: block;
                &::after {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: black;
                    border: 1px solid black;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(60%, -50%);
                }   
            }
    }
}