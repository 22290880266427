/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutPayment {
    border: 0;
    &-Icons {
        position: absolute;
        right: 0;
        text-align: right;
        img {
            padding-left: 10px;
            height: 40px;
            width: auto;
            &:first-of-type {
                padding-left: 0;
            }
        }
        @include mobile {
            position: relative;
            margin-top: 10px;
            margin-left: 30px;
            flex-basis: 100%;
            text-align: left;

        }
    }
    &-Button {
        width: 100%;
        font-size: 21px;
        font-weight: 700;
        flex-flow: wrap;
        color: black;
        @include mobile {
            width: 100%;
            font-size: 18px;
        }
        .Field {
            text-align: left;
        }
        span {
            font-size: 20px;
            font-weight: 700;
            padding-left: 36px;
            margin-bottom: 10px;
            @include mobile {
                font-size: 18px;
            }
        }
        .Field [type='checkbox'] + .input-control { 
        border: 1px solid black;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &:hover {
            &:after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 4px;
                top: 4px;
                box-shadow: none;
            }
        }
        }
        .Field [type='checkbox']:checked + .input-control::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: black;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            left: 4px;
            top: 4px;
            box-shadow: none;
        }
        &_isSelected {
            .CheckoutPayment {
                &-Cms {
                    display: block;
                    margin-top: 20px;
                    padding-left: 30px;
                    margin-left: 30px;
                    text-align: left;
                    @include mobile {
                        margin-top: 0;
                        padding-left: 0;
                        margin-left: 30px;
                        text-align: left;
                    }
                }
            }
        }
    }
    &-Cms {
        flex: 1 100%;
        display: none;
    }
}