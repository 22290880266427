/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutShipping {
    .AcceptanceControl-Wrapper {
        margin-bottom: 2rem;
        h2 {
            @include desktop {
                font-size: 18px;
            }
        }
        .Field_type_checkbox {
            margin-top: 0;
            margin-bottom: 15px;
        }
        .Field {
            span {
                font-size: 18px;
                @include desktop {
                    font-size: 18px;
                }
            }
        }

    }

    .Checkout {
        &-AgeControl, &-Surcharge {
            h2 {
                @include desktop {
                    font-size: 18px;
                }
            }
            .Field {
                margin: 15px 0;
                span {
                    font-size: 18px;
                    @include desktop {
                        font-size: 18px;
                    }
                }
            }
        }
        &-Asterix { padding-left: 1rem; }
    }
    @include desktop {
        &-Button {
          border-radius: 10px;
          &:not([disabled]):hover {
            border-radius: 10px;
          }
        }
      }
    // &-Button {
    //     @include desktop {
    //         border-radius: 10px;
    //     }
    //     @include desktop {
    //         &-CheckoutButton {
    //           border-radius: 10px;
    //           &.Button:not([disabled]):hover {
    //             border-radius: 10px;
    //           }
    //         }
    //       }
    // }

}