/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutSuccess {
    h3{
        text-transform: none;
    }
    &-ButtonWrapper {
        @include mobile {
            padding: 14px;
            position: fixed;
            inset-inline-start: 0;
            width: 100%;
            inset-block-end: var(--navigation-tabs-height);
            border-block-start: 1px solid var(--primary-divider-color);
            z-index: 1;
            background: #f9e4c5;
            border-bottom:1px solid var(--primary-divider-color);
            margin-bottom: -2px;
        }
    }
}
.Checkout {
    &-ProgressSection { 
      margin-top: 24px;
      @include mobile {
        margin-top: 0;
      }
      .Checkout {
          &-Header {
              width: 100%;
              z-index: 3;
              min-height: 75px;
              padding-top: 10px;
              padding-bottom: 10px;
          }
          &-Step {
              width: 100%;
              &-Label {
                  width: 100%;
                  display: block;
              }
          }
          &-Step1,
          &-Step2,
          &-Step3,
          &-Step4 {
              width: 25%;
              float: left;
              text-align: center;
              &-Icon {
                  .LockIcon {
                      fill: #00499b;
                      margin-right: 0;
                      border: 1px solid #00499b;
                      border-radius: 50%;
                      width: 34px;
                      height: 34px;
                  }
              }
          }
          &-IconWrapper {
              background: #fdf5e8;
              width: 50px;
              display: block;
              text-align: center;
              margin: 0 auto;
              &_active {
                  .LockIcon {
                      background: #00499b;
                      fill: white;
                  }
              }
          }
      }
    }
    &-StepBarTotal {
      position: absolute;
      top: 28px;
      width: 69%;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 2px;
      background-color: #174a97;
      opacity: 1;
  }
  }