/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Checkout {
    &-Title {
        font-size: 44px;
        padding-bottom: 20px;
        @include desktop {
            padding-top: 48px;
        }
        @include mobile {
            font-size: 30px;
            padding-bottom: 10px;
        }
    }
    @include mobile{
        margin-top: 0;
    }
    .ContentWrapper.Checkout-Wrapper {
        padding: 0 16px;
    }
    &-Header {
        display: block;
        .CmsBlock-Wrapper {
            p {
                font-size: 18px;
            }
        }
    }
    &-Heading h2 {
        font-size: 30px;
        @include desktop {
            font-size: 30px;
        }
    }
    &-Heading {
        font-size: 30px;
    }
    .MyAccountOverlay {
        &-SignInButton {
            text-align: center;
            button {
                width: auto;
                border-radius: 10px;
                &:hover {
                    border-radius: 10px;
                }
            }
        }
    }

    &-ProgressSection { 
        margin-top: 20px;
        @include mobile {
            display: block;
        }
        .Checkout {
            &-Header {
                width: 100%;
                z-index: 3;
                min-height: 75px;
            }
            &-Step {
                width: 100%;
                &-Label {
                    width: 100%;
                    display: block;
                }
            }
            &-Step1,
            &-Step2,
            &-Step3,
            &-Step4 {
                width: 25%;
                float: left;
                text-align: center;
                &-Icon {
                    .LockIcon {
                        fill: #00499b;
                        margin-right: 0;
                        border: 1px solid #00499b;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                    }
                }
            }
            &-IconWrapper {
                background: #fdf5e8;
                width: 50px;
                display: block;
                text-align: center;
                margin: 0 auto;
                &_active {
                    .LockIcon {
                        background: #00499b;
                        fill: white;
                    }
                }
            }
        }
    }
    &-StepBarTotal {
        position: absolute;
        top: 28px;
        width: 69%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 2px;
        background-color: #b0c1c9;
        opacity: 0.75;
    }
    &-StepBarActive {
        width: 20%;
        position: absolute;
        top: 28px;
        left: 15%;
        height: 2px;
        background-color: #174a97;
    }
    &-StepBarActive.isSecond {
        width: 46%;
    }

    &Payments {
        .adyen-checkout {
            &__input {
                font-size: 16px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Muli", "Helvetica Neue", sans-serif;
            }
            &__button__text {
                overflow: visible;
            }
        }
    }
    .Field-LabelContainer {
        display: none;
    }
    &-StickyButtonWrapper {
        text-align: center;
    }
    h2 {
        @include desktop {
            font-size: 30px;
        }
    }
    .CmsBlock-Wrapper p {
        font-size: 18px;
        @include desktop {
            font-size: 18px;
        }
        @include mobile {
            line-height: 20px;
        }
    }
}
.CheckoutGuestForm {
    @include mobile {
        margin: 0;
    }
}
