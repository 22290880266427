/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutNewsletter {
    &-SignUp {
        width: 46%;
        margin-left: auto;
        @include mobile {
            width: 100%;
            margin-left: 0;
        }
        &-Fields {
            .Field {
                &_type {
                    &_checkbox {
                        margin-top: 10px;
                        label {
                            flex-direction: row;
                            justify-content: flex-start;
                            display: block;
                            // .CmsBlock-Wrapper {
                            //     display: inline-block;
                            // }
                        }
                        span {
                            font-size: 20px;
                            font-weight: 700;
                            padding-left: 36px;
                            margin-bottom: 10px;
                            @include desktop {
                                font-size: 27px;
                            }
                        }
                    }
                }
            }
        }
    }
    
}