/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutGuestForm {
    &-CreateAccount,
    &-Continue {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        @include mobile {
            display: block;
            width: 100%;
        }
    }
    &-Buttons {
        text-align: center;
        margin-top: 20px;
    }
    &-CreateAccount,
    &-Continue {
        .Field {
            &_type {
                &_checkbox {
                    label {
                        flex-direction: row;
                        justify-content: flex-start;
                    }
                    span {
                        font-size: 20px;
                        font-weight: 700;
                        padding-left: 36px;
                        margin-bottom: 10px;
                        @include desktop {
                            font-size: 27px;
                        }
                    }
                }
            }
        }
        .Field [type='checkbox'] + .input-control { 
            border: 1px solid black;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-bottom: 3px;
            @include desktop {
                margin-bottom: 8px;
            }
            &:hover {
              &:after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 4px;
                top: 4px;
                box-shadow: none;
              }
            }
          }
          .Field [type='checkbox']:checked + .input-control::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 4px;
                top: 4px;
                box-shadow: none;
           }
    }
    .CheckoutNewsletter {
        &-SignUp {
            .Field {
                &_type {
                    &_checkbox {
                        margin-top: 10px;
                        label {
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: start;
                            display: block;
                            .input-control {
                                margin-top: 3px;
                                float: left;
                                @include mobile {
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                        span {
                            font-size: 18px;
                            font-weight: 700;
                            padding-left: 36px;
                            margin-bottom: 10px;
                            float: right;
                            @include desktop {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}