/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.Checkout-ProgressSection {
    .Checkout {
        &-IconWrapper, &-IconWrapper_active {
            width: 50px;
            margin: 0 auto;
            background: #FDF5E8;
        }
        &-IconWrapper {
            .Checkout {
                &-StepButton:hover {
                    cursor: pointer;
                }
            }
        }
        &-Step-Label {
            font-size: 1.5rem; //TODO
            color: var(--primary-base-color);
            &.Unvisited {
                color: #b0c1c9;
            }
        }
        &-ProgressIcon {
            width: auto;
            height: 35px;
        }
    }
}