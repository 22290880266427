/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
// #BILLING_STEP.CheckoutBilling {
//     margin-top: 0;
//     .Checkout-Heading {
//         margin-top: 20px;
//     }
//     &-Checkbox {
//         .Field-CheckboxLabel span {
//             font-size: 20px;
//             font-weight: 700;
//         }
//     }
// }
.CheckoutBilling {
    &-Checkbox {
        span {
            font-size: 18px;
            font-weight: 700;
            @include desktop {
                font-size: 21px;
            }
        }
    }
    &-Address {
        padding-left: 28px;
        padding-top: 10px;
        p {
            margin-bottom: 0;
        }
    }
    .Field [type='checkbox'] + .input-control { 
        border: 1px solid black;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        &:hover {
            &:after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 4px;
                top: 4px;
                box-shadow: none;
            }
        }
        }
        .Field [type='checkbox']:checked + .input-control::after {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            background-color: black;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            left: 4px;
            top: 4px;
            box-shadow: none;
        }
        &-Checkbox {
            @include mobile {
                > .Field-CheckboxLabel {
                    display: block;
                    .input-control {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(0%, -50%);
                    }
                    > span {
                        display: inline-block;
                        margin-left: 27px;
                    }
                }
                
            }
        }
}