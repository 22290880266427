/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutOrderSummary {
    border: 0;
    .BasketTotalMessage {
        padding-top: 14px;
        @include desktop {
            padding-top: 12px;
        }
    }
    &-EditCart,
    &-EditShipping {
        position: absolute;
        right: 0;
        top: -4px;
    }
    &-Header {
        border-bottom: 1px solid #676056;
        padding-bottom: 18px;
        h2 {
            @include  desktop {
                font-size: 30px;
            }
        }
    }
    &-ItemsInCart,
    &-DeliveryTitle {
        font-size: 27px;
        font-weight: 800;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &-DeliveryDetails {
        border-bottom: 1px solid #676056;
        padding-bottom: 10px;
        .CheckoutOrderSummary {
            &-Text {
                word-wrap: break-word;
                word-break: break-all;
                max-width: 100%;
                text-align: left;
                @include desktop {
                    font-size: 18px;
                }
                p {
                    font-weight: bold;
                    margin-bottom: 0;
                    font-size: 18px;
                    @include desktop {
                        font-size: 18px;
                    }
                }
            }
            &-SummaryItem {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(91px, 1fr));
            }
        }
    }
    &-OrderTotals {
        .CheckoutOrderSummary {
            &-SummaryItem {
                padding:  10px 0;
            }
        }
    }
    &-Text {
        font-size: 18px;
    }
    &-SummaryItem_isTotal{
        justify-content: unset;
        font-size: 18px;
        border-color: #676056;
        strong:last-child {
            margin-left: auto;
        }
        .CheckoutOrderSummary-Text {
            font-size: 18px;
        }
    }
    &-Asterix { padding-left: 1rem; }
    &-ButtonWrapper {
        @include mobile {
            height: auto;
            position: fixed;
            width: 100%;
            inset-inline-start: 0;
            padding: 16px;
            inset-block-end: var(--footer-total-height);
            background-color: var(--color-white);
            border-block-start: 1px solid var(--primary-divider-color);
            z-index: 80;
        }
    }
    @include mobile {

    }
    .CartItem-QuantityWrapper {
        margin-top: 10px;
    }
    &-CartItemList {
        .CartItem-Heading {
            @include desktop {
                font-size: 18px;
            }
        }
    }
}
