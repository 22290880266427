/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutAddressTable {
    .KeyValueTable {
        &-Wrapper {
            text-align: left;
            padding-left: 30px;
            color: black;
        }
        &-Heading {
            display: none;
        }
        &-street,
        &-telephone {
            display: block;
            width: 100%;
        }
        thead {
            display: none;
        }
        tr {
            display: inline-block;
            border-bottom: 0;
            th {
                display: none;
            }
            td {
                min-width: auto;
                padding: 0 5px 0 0;
            }
        }
    }
    &-Button {
        border: 0;
        &::before {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 1px solid black;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 4px;
        }
        &:hover {
            border: 0;
            &::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 5px;
                top: 9px;
            }
        }
        &_isSelected {
            &::after {
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                background-color: black;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                left: 5px;
                top: 9px;
            }
        }
    }
}