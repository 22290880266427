/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutPayments {
    &-Methods {
        width: 100%;
        @include mobile {
            li.CheckoutPayment {
                padding-top: 10px;
                padding-bottom: 0;
            }
        }

        .Checkout {
            &-StickyButtonWrapper {
                .CheckoutBilling-Button {
                    border-radius: 10px;
                }
                &.hideButton {
                    display: none;
                }
                &.adyen_cc {
                    padding-top: 20px;
                }
                &.adyen_hpp_klarna {
                    .CheckoutBilling-Button {
                        color: black;
                        background: #ffa8cd; // official Klarna pink colour
                        border-color: #ffa8cd
                    }
                }
            }
        }
    }
    // Twint place order button
    & > div:last-of-type {
        display: flex;
        justify-content: center;
        .adyen-checkout {
            &__button {
                width: 294px;
                &__text {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
}